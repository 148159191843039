import { ShortcutsConfig, useHotkeys } from '@kontent-ai/component-library/hooks';
import { SrOnly } from '@kontent-ai/component-library/styles';
import { FC, PropsWithChildren, useRef } from 'react';
import {
  DataUiAction,
  DataUiWorkflowAction,
  getDataAttributeProps,
} from '../../utils/dataAttributes/DataUiAttributes.ts';

interface ILinkLikeProps {
  readonly className?: string;
  readonly onClick?: () => void;
  readonly dataUiAction?: DataUiAction | DataUiWorkflowAction;
  readonly screenReaderText?: string;
}

export const LinkLike: FC<PropsWithChildren<ILinkLikeProps>> = (props) => {
  const ref = useRef(null);
  const handleKeyboardInteraction = (e: KeyboardEvent): void => {
    if (props.onClick) {
      props.onClick();
      e.stopPropagation();
    }
  };
  useHotkeys(
    {
      [ShortcutsConfig.Enter]: handleKeyboardInteraction,
    },
    { ref },
  );

  return (
    <a
      className={props.className}
      onClick={props.onClick}
      ref={ref}
      tabIndex={0}
      {...getDataAttributeProps(props)}
    >
      {props.screenReaderText && <SrOnly>{props.screenReaderText}</SrOnly>}
      {props.children}
    </a>
  );
};

LinkLike.displayName = 'LinkLike';
